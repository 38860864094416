import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../util/routes';
import { createSlug } from '../../util/urlHelpers';
import Slider from 'react-slick';
import css from './CarouselSection.module.css';
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage';
import StarRatings from 'react-star-ratings';
import CustomArrow from '../CustomArrow/CustomArrow';
import { FILM_LABEL, FILM_PRODUCTS, SERIES_LABEL } from '../../util/types';
import { formatCardDuration, formatLabel } from '../../util/dataExtractor';
import ListingCard from '../ListingCard/ListingCard';
import { getCurrentDeviceWidth } from '../CurrentViewPort/CurrentViewPort';
import Button from '../Button/Button';
import IconCollection from '../IconCollection/IconCollection';
import { TRENDING_CREATORS } from '../../constants';
import classNames from 'classnames';

const renderItem = ({ item, index, showTopRightButton, handleTopRightButton }) => {
  const { listingType, primary_genre, series_primary_genre } = item?.attributes?.publicData || {};
  const id = item?.id?.uuid;
  const genre = primary_genre.length ? primary_genre : series_primary_genre;

  return (
    <div key={`${item.id}-${index}`} style={{ width: 295 }} className={css.creatorCard}>
      {showTopRightButton ? (
        <div className={css.removeButton}>
          <span
            className={css.removeIcon}
            onClick={() => handleTopRightButton(id, listingType, genre)}
          >
            {' '}
            <IconCollection icon="minus-icon-circle" />
          </span>
        </div>
      ) : null}
      <ListingCard listing={item} showAuthorInfo={false} showDetails={true} />
    </div>
  );
};

const prepareItemData = item => {
  const isListing = !!item?.state;

  if (isListing) {
    const { objectID, title, publicData, state } = item || {};
    const {
      listingType: category,
      primary_genre = [],
      marketingPoster,
      ratingAvg,
      episodeCount,
      listingType,
      series_primary_genre,
      episodes = [],
      listingRating,
      filmDuration,
    } = publicData || {};

    const primaryGenreMaybe = series_primary_genre?.length ? (
      <span>{series_primary_genre.map(g => formatLabel(g)).join(', ')}</span>
    ) : primary_genre?.length ? (
      <span>{primary_genre.map(g => formatLabel(g)).join(', ')}</span>
    ) : (
      ''
    );

    const duration = filmDuration ? (
      <span>{formatCardDuration(filmDuration)} </span>
    ) : episodeCount || episodes?.length ? (
      <span>{episodeCount || episodes?.length} Episodes</span>
    ) : null;

    return {
      objectID,
      name: title,
      url: marketingPoster?.sourceUrl,
      key: marketingPoster?.key,
      rating: ratingAvg,
      primaryGenreMaybe,
      duration,
      listingType,
      isListing,
      listingRating,
    };
  } else {
    const {
      objectID,
      firstName,
      lastName,
      displayName,
      username,
      totalFilms,
      totalSeries,
      userProfileImage,
      marketingPosters,
    } = item || {};

    return {
      objectID,
      name: displayName || `${firstName} ${lastName}`,
      username,
      key: userProfileImage?.key,
      totalFilms,
      totalSeries,
      isListing,
      marketingPosters,
      displayName,
    };
  }
};

const algoliaRenderItem = (item, index, history, routeConfiguration, isCreatorSection) => {
  const {
    objectID,
    name,
    key,
    rating,
    totalFilms,
    totalSeries,
    username,
    primaryGenreMaybe,
    duration,
    listingType,
    isListing,
    listingRating,
    displayName,
    marketingPosters
  } = prepareItemData(item) || {};

  const films = `${totalFilms} Films`;
  const series = `${totalSeries} Series`;

  const handleClick = () => {
    history.push(
      createResourceLocatorString(
        isListing ? 'ListingPage' : 'ProfilePage',
        routeConfiguration,
        { id: objectID, ...(isListing ? { slug: createSlug(name) } : { username: username || name }) },
        {}
      )
    );
  };

  const details = isListing ? (
    <p>
      {listingType == FILM_PRODUCTS ? FILM_LABEL : SERIES_LABEL}&nbsp;|&nbsp;{primaryGenreMaybe}
      &nbsp;|&nbsp;
      {duration}
    </p>
  ) : (
    <p>
      {films} | {series}
    </p>
  );

  const isValid = key && name && objectID;
  const deviceWidth = getCurrentDeviceWidth();

  if (!isValid) {
    return null;
  }

  return (
    <div key={`${item.id}-${index}`} style={{ width: deviceWidth > 767 ? 295 : 265 }}>
      <div onClick={handleClick} className={css.card}>
        <div className={isCreatorSection ? classNames(css.imageContainer, css.creatorImageContainer) : css.imageContainer}>
          <ResponsiveImage
            alt={name}
            className={isCreatorSection ? classNames(css.cardImage, css.creatorImage) : css.cardImage}
            variants={['default']}
            gumletImage={{
              sourceUrl: process.env.REACT_APP_GUMLET_SOURCE_URL,
              key: key,
            }}
            // transformWidth={500}
          />
          {isCreatorSection
            ? (totalFilms > 0 || totalSeries > 0) ? (
              <div className={css.smallImg}>
                {marketingPosters?.length > 0
                  ? marketingPosters
                    .slice(-3) // Get last 3 elements
                    .reverse() // Reverse the sliced array
                    .map((el, index) => <ResponsiveImage key={index} url={el} alt={username} />)
                  : null}
              </div>
            ) : <div className={css.smallImgPlaceholder}>
              <IconCollection icon="coming-soon-media" />
              <p>Content Coming Soon</p>
            </div>
            : null}
        </div>
        <div className={css.cardBody}>
          {rating ? (
            <div className={css.rating}>
              <StarRatings rating={rating} starDimension="25px" starSpacing="2px" />
            </div>
          ) : username ? (
            <div className={css.username}>{username}</div>
          ) : null}
          {isListing ? (
            <div className={css.ratings}>
              <StarRatings
                svgIconViewBox="0 0 40 37"
                svgIconPath="M20 0L26.113 11.5862L39.0211 13.8197L29.891 23.2138L31.7557 36.1803L20 30.4L8.2443 36.1803L10.109 23.2138L0.97887 13.8197L13.887 11.5862L20 0Z"
                starRatedColor="#ffb802"
                // starEmptyColor="#ffffff"
                rating={listingRating ? listingRating : 0}
                starDimension="25px"
                starSpacing="2px"
              />
            </div>
          ) : null}
          <h3 className={css.cardTitle}>{name}</h3>
          <div className={css.cardDetails}>{details}</div>
        </div>
      </div>
    </div>
  );
};

const CarouselSection = ({
  name,
  description,
  items,
  handleAction = () => { },
  algolia = false,
  fetchNext = () => { },
  isWishlistPage = false,
  showTopRightButton,
  handleTopRightButton,
}) => {
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [slideCount, setSlideCount] = React.useState(0);
  const isCreatorSection = name == TRENDING_CREATORS;

  const handleBeforeChange = (current, next) => {
    setCurrentSlide(next);
  };

  const handleAfterChange = (current, next) => {
    setCurrentSlide(current);

    if (next == undefined) {
      fetchNext();
    }
  };

  const settings = {
    className: 'slider variable-width',
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    swipe: false,
    slidesToScroll: 1,
    variableWidth: true,
    swipeToSlide: false,
    speed: 500,
    prevArrow: <CustomArrow direction="prev" disabled={currentSlide === 0} />,
    nextArrow: <CustomArrow direction="next" disabled={currentSlide >= slideCount - 4} />, // Assuming 4 visible items
    beforeChange: (current, next) => handleBeforeChange(current, next),
    afterChange: (current, next) => handleAfterChange(current, next),
    onInit: () => setSlideCount(items.length),
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          arrows: false,
          swipe: true,
          swipeToSlide: true,
        },
      },
    ],
  };

  return (
    <div className={`${css.carouselSection} ${isWishlistPage ? css.wishlistCarousel : ''}`}>
      {name || description ? (
        <div className={css.header}>
          {name ? <div className={css.title}>{name}</div> : null}
          {description ? (
            <div onClick={handleAction} className={css.viewAll}>
              {description}
            </div>
          ) : null}
        </div>
      ) : null}
      <div className={css.hitsContainer}>
        <Slider {...settings}>
          {items.map((item, index) =>
            algolia
              ? algoliaRenderItem(item, index, history, routeConfiguration, isCreatorSection)
              : renderItem({ item, index, showTopRightButton, handleTopRightButton })
          )}
        </Slider>
      </div>
    </div>
  );
};

export default CarouselSection;
