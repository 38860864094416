import { LISTING_TYPE_FILMS, LISTING_TYPE_SERIES } from '../../constants';
import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { getFeaturedCreators } from '../../util/api';

const defaultSchemaFilters = {
  pub_markAsDraft: false,
  pub_deletedListing: false,
  pub_privateMode: false,
};
const includeParams = {
  include: ['author', 'author.profileImage', 'images', 'currentStock'],
  'fields.image': [
    // Scaled variants for large images
    'variants.scaled-small',
    'variants.scaled-medium',
    'variants.scaled-large',
    'variants.scaled-xlarge',

    // Social media
    'variants.facebook',
    'variants.twitter',

    // Avatars
    'variants.square-small',
    'variants.square-small2x',
  ],
};

export const LANDING_PAGE = 'landing-page';
export const GUEST_LANDING_PAGE = 'guest-landing-page';

export const FETCH_RECENT_LISTINGS_REQUEST = 'app/OtherSections/FETCH_RECENT_LISTINGS_REQUEST';
export const FETCH_RECENT_LISTINGS_SUCCESS = 'app/OtherSections/FETCH_RECENT_LISTINGS_SUCCESS';
export const FETCH_RECENT_LISTINGS_ERROR = 'app/OtherSections/FETCH_RECENT_LISTINGS_ERROR';

export const FETCH_SERIES_LISTINGS_REQUEST = 'app/OtherSections/FETCH_SERIES_LISTINGS_REQUEST';
export const FETCH_SERIES_LISTINGS_SUCCESS = 'app/OtherSections/FETCH_SERIES_LISTINGS_SUCCESS';
export const FETCH_SERIES_LISTINGS_ERROR = 'app/OtherSections/FETCH_SERIES_LISTINGS_ERROR';

export const FETCH_FILMS_LISTINGS_REQUEST = 'app/OtherSections/FETCH_FILMS_LISTINGS_REQUEST';
export const FETCH_FILMS_LISTINGS_SUCCESS = 'app/OtherSections/FETCH_FILMS_LISTINGS_SUCCESS';
export const FETCH_FILMS_LISTINGS_ERROR = 'app/OtherSections/FETCH_FILMS_LISTINGS_ERROR';

export const FETCH_FEATURED_CREATORS_REQUEST = 'app/OtherSections/FETCH_FEATURED_CREATORS_REQUEST';
export const FETCH_FEATURED_CREATORS_SUCCESS = 'app/OtherSections/FETCH_FEATURED_CREATORS_SUCCESS';
export const FETCH_FEATURED_CREATORS_ERROR = 'app/OtherSections/FETCH_FEATURED_CREATORS_ERROR';

const initialState = {
  recentListingsInProgress: false,
  recentListings: [],
  recentListingsError: null,

  seriesListingsInProgress: false,
  seriesListings: [],
  seriesListingsError: null,

  filmsListingsInProgress: false,
  filmsListings: [],
  filmsListingsError: null,

  featuredCreatorsInProgress: false,
  featuredCreators: [],
  featuredCreatorsError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_RECENT_LISTINGS_REQUEST:
      return { ...state, recentListingsInProgress: true, recentListingsError: null };
    case FETCH_RECENT_LISTINGS_SUCCESS:
      return {
        ...state,
        recentListings: payload,
        recentListingsInProgress: false,
        recentListingsError: null,
      };
    case FETCH_RECENT_LISTINGS_ERROR:
      return {
        ...state,
        recentListings: [],
        recentListingsInProgress: false,
        recentListingsError: payload,
      };
    case FETCH_SERIES_LISTINGS_REQUEST:
      return { ...state, seriesListingsInProgress: true, seriesListingsError: null };
    case FETCH_SERIES_LISTINGS_SUCCESS:
      return {
        ...state,
        seriesListings: payload,
        seriesListingsInProgress: false,
        seriesListingsError: null,
      };
    case FETCH_SERIES_LISTINGS_ERROR:
      return {
        ...state,
        seriesListings: [],
        seriesListingsInProgress: false,
        seriesListingsError: payload,
      };
    case FETCH_FILMS_LISTINGS_REQUEST:
      return { ...state, filmsListingsInProgress: true, filmsListingsError: null };
    case FETCH_FILMS_LISTINGS_SUCCESS:
      return {
        ...state,
        filmsListings: payload,
        filmsListingsInProgress: false,
        filmsListingsError: null,
      };
    case FETCH_FILMS_LISTINGS_ERROR:
      return {
        ...state,
        filmsListings: [],
        filmsListingsInProgress: false,
        filmsListingsError: payload,
      };
    case FETCH_FEATURED_CREATORS_REQUEST:
      return { ...state, featuredCreatorsInProgress: true, featuredCreatorsError: null };
    case FETCH_FEATURED_CREATORS_SUCCESS:
      return {
        ...state,
        featuredCreators: payload,
        featuredCreatorsInProgress: false,
        featuredCreatorsError: null,
      };
    case FETCH_FEATURED_CREATORS_ERROR:
      return {
        ...state,
        featuredCreators: [],
        featuredCreatorsInProgress: false,
        featuredCreatorsError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //
const requestAction = actionType => params => ({ type: actionType, payload: { params } });
const successAction = actionType => result => ({ type: actionType, payload: result.data });
const errorAction = actionType => payload => ({ type: actionType, payload, error: true });

export const fetchRecentListingRequest = requestAction(FETCH_RECENT_LISTINGS_REQUEST);
export const fetchRecentListingSuccess = successAction(FETCH_RECENT_LISTINGS_SUCCESS);
export const fetchRecentListingError = errorAction(FETCH_RECENT_LISTINGS_ERROR);

export const fetchSeriesListingRequest = requestAction(FETCH_SERIES_LISTINGS_REQUEST);
export const fetchSeriesListingSuccess = successAction(FETCH_SERIES_LISTINGS_SUCCESS);
export const fetchSeriesListingError = errorAction(FETCH_SERIES_LISTINGS_ERROR);

export const fetchFilmsListingRequest = requestAction(FETCH_FILMS_LISTINGS_REQUEST);
export const fetchFilmsListingSuccess = successAction(FETCH_FILMS_LISTINGS_SUCCESS);
export const fetchFilmsListingError = errorAction(FETCH_FILMS_LISTINGS_ERROR);

export const fetchfeaturedCreatorsRequest = requestAction(FETCH_FEATURED_CREATORS_REQUEST);
export const fetchfeaturedCreatorsSuccess = successAction(FETCH_FEATURED_CREATORS_SUCCESS);
export const fetchfeaturedCreatorsError = errorAction(FETCH_FEATURED_CREATORS_ERROR);

export function fetchLatestContent() {
  return (dispatch, getState, sdk) => {
    dispatch(fetchRecentListingRequest());
    return sdk.listings
      .query({ sort: 'createdAt', ...defaultSchemaFilters, perPage: 10, ...includeParams })
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchRecentListingSuccess(response?.data));
      })
      .catch(e => {
        dispatch(fetchRecentListingError(e));
      });
  };
};

export function fetchSeriesListings() {
  return (dispatch, getState, sdk) => {
    dispatch(fetchSeriesListingRequest());
    return sdk.listings
      .query({
        pub_listingType: LISTING_TYPE_SERIES,
        ...defaultSchemaFilters,
        perPage: 10,
        ...includeParams,
      })
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchSeriesListingSuccess(response?.data));
      })
      .catch(e => {
        dispatch(fetchSeriesListingError(e));
      });
  };
};

export function fetchFilmsListings() {
  return (dispatch, getState, sdk) => {
    dispatch(fetchFilmsListingRequest());
    return sdk.listings
      .query({
        pub_listingType: LISTING_TYPE_FILMS,
        ...defaultSchemaFilters,
        perPage: 10,
        ...includeParams,
      })
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchFilmsListingSuccess(response?.data));
      })
      .catch(e => {
        dispatch(fetchFilmsListingError(e));
      });
  };
};

export function fetchFeaturedCreators() {
  return (dispatch, getState, sdk) => {
    dispatch(fetchfeaturedCreatorsRequest());
    return getFeaturedCreators()
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchfeaturedCreatorsSuccess(response?.data));
      })
      .catch(e => {
        dispatch(fetchfeaturedCreatorsError(e));
      });
  };
};

export const recentListingsSelector = state => state.LandingPage.recentListings;
export const seriesListingsSelector = state => state.LandingPage.seriesListings;
export const filmsListingsSelector = state => state.LandingPage.filmsListings;
export const featuredCreatorsSelector = state => state.LandingPage.featuredCreators;

export const loadData = (params, search) => (dispatch, getState) => {
  const { isAuthenticated } = getState().auth;
  // const ASSET_NAME = isAuthenticated ? LANDING_PAGE : GUEST_LANDING_PAGE;
  const ASSET_NAME = GUEST_LANDING_PAGE;
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
  return dispatch(fetchPageAssets(pageAsset, true));
};
