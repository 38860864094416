import React from 'react';
import Button from '../Button/Button';
import css from './CustomArrow.module.css';

const CustomArrow = ({ direction, onClick,disabled }) => (
  <Button disabled={disabled} onClick={onClick} rootClassName={css[`${direction}Arrow`]}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
    >
      <g clipPath="url(#clip0_2253_5879)">
        <path
          d="M0.22398 8.16448L0.224077 8.16458C0.671583 7.73798 1.26612 7.5 1.88439 7.5C2.50266 7.5 3.0972 7.73798 3.5447 8.16458L24.7917 28.4089C25.0275 28.6336 25.2153 28.9039 25.3436 29.2033C25.4719 29.5028 25.5381 29.8252 25.5381 30.151C25.5381 30.4768 25.4719 30.7992 25.3436 31.0986C25.2153 31.3981 25.0275 31.6684 24.7917 31.8931L3.5447 52.1374C3.09719 52.564 2.50265 52.802 1.88439 52.802C1.26612 52.802 0.671581 52.564 0.224075 52.1374L0.215422 52.1291L0.215523 52.129L0.196924 52.1104C-0.0224037 51.9008 -0.197168 51.6491 -0.316874 51.3702C-0.437468 51.0893 -0.499655 50.7868 -0.499655 50.4811C-0.499655 50.1755 -0.437467 49.873 -0.316873 49.5921C-0.196279 49.3112 -0.0198039 49.0577 0.201822 48.8472L19.8293 30.1547L0.201828 11.4548L0.22398 8.16448ZM0.22398 8.16448L0.215538 8.17292M0.22398 8.16448L0.215538 8.17292M0.215538 8.17292L0.196897 8.19156M0.215538 8.17292L0.196897 8.19156M0.196897 8.19156C-0.022419 8.40118 -0.197174 8.65293 -0.316872 8.93174C-0.437466 9.21264 -0.499653 9.51514 -0.499653 9.82083C-0.499653 10.1265 -0.437466 10.429 -0.316872 10.7099C-0.196373 10.9906 -0.0200769 11.2439 0.201305 11.4543L0.196897 8.19156Z"
          fill="white"
          stroke="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2253_5879">
          <rect width="61" height="61" fill="white" transform="translate(61) rotate(90)" />
        </clipPath>
      </defs>
    </svg>
  </Button>
);

export default CustomArrow;